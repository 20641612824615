import styled from 'styled-components';

import {
  device
} from '../../utils';

export const Wrapper = styled.div `
  margin: 10px 0;
  width: 100%;

  nav {
    margin-bottom: 20px;
  }
`;

export const Content = styled.div `
  display: grid;
  grid-gap: 40px;
  margin: 20px 0;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.laptop} {
    grid-template-columns: 400px 1fr;
  }
`;

export const Video = styled.div `
  /* .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25%;
    margin-left: -12.5%;
    margin-top: -12.5%;
  }


  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  .image-wrapper {
    position: relative;
  } */

  iframe {
    width: 100%;
  }
`;

export const Description = styled.div ``;