import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../../components/seo';

// import PartnerScreen from '../screens/page/partner';
import { regroup } from '../../utils';
import usePreviewData from '../../utils/usePreviewData';
import StoryScreen from '../../screens/stories/Page';

const PageTemplate = ({ data: rawData }) => {
  const data = usePreviewData(rawData);
  const page = regroup({
    ...data?.prismicStories?.data,
    uid: data?.prismicStories.uid
  });

  return (
    <>
      <Seo
        title={page.title}
        description={page?.description?.text}
        image={page?.cover_background?.url}
      />
      <StoryScreen
        story={page}
        related={data.related?.nodes || []}
      />
    </>
  );
};

export const query = graphql`
  query(
    $prismic: ID!,
    $cmsLocaleGlob: String!,
    $categoryPrismicId: ID
  ) {
    prismicStories(
      prismicId: { eq: $prismic },
    ) {
      uid
      data {
        title
        video_full {
          html
        }
        description {
          html
          text
        }
        cover_background {
          dimensions {
            height
            width
          }
          url
          alt
        }
      }
    }
    related: allPrismicStories(
      filter: {
        lang: {
          glob: $cmsLocaleGlob
        }
        data: {
          category: { id: { eq: $categoryPrismicId } }
        }
        prismicId: { ne: $prismic }
      }
    ) {
      nodes {
        id
        url
        data {
          title
          description {
            html
          }
          cover_title {
            url
            dimensions {
              height
              width
            }
            alt
          }
          cover_banner
          cover_background {
            dimensions {
              height
              width
            }
            alt
            url
          }
          video_full {
            embed_url
            html
          }
          video_preview
        }
      }
    }
  }
`;
export default PageTemplate;