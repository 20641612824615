import React from 'react';
import Title from '../../components/title';

import Html from '../../components/common/Html';

import { Wrapper, Content, Video, Description } from './Page.styles';
import StoriesList from '../../components/stories/List';
import { StoriesBreadcrumb } from '../../components/common/Breadcrumb';
import { useLocale } from '../../services/i18n/useLocale';

const iframeRegex = /<iframe[^>]*src=["|']([^'"]+)["|'][^>]*>/i;

const VideoWrapper = ({
  cover,
  video,
}) => {
  if (!cover || !video?.html) {
    return null;
  }

  const [, src] = video?.html.match(iframeRegex);
  let newSrc = src.split('?').shift();
  // newSrc += '?autoplay=1';
  const embedMarkup = video.html.replace(src, newSrc);

  return (
    <Video /*onClick={() => setPlay(true)}*/>
      <div className="image-wrapper">
        {/* <Imgix {...cover} sizes={imgResp} /> */}
        {/* <Icon src={VideoPlay} /> */}
        {/* {play && ( */}
        <div dangerouslySetInnerHTML={{ __html: embedMarkup }} />
        {/* )} */}
      </div>
    </Video>
  );
};

const StoryScreen = ({
  story = {},
  related = []
}) => {
  const {
    title,
    description,
    cover,
    video
  } = story;
  const intl = useLocale();

  return (
    <Wrapper>
      <div className="container">
        <StoriesBreadcrumb story={story} />
        <Title title={title} />
        <Content>
          <VideoWrapper
            cover={cover?.background}
            video={video?.full}
          />
          <Description>
            <Html html={description?.html} />
          </Description>
        </Content>
      </div>
      <StoriesList
        title={intl.formatMessage('ecom.widgets.recommendation')}
        items={related}
      />
    </Wrapper>
  );
};

export default StoryScreen;
